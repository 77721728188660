var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-2xl"},[_vm._v(" Análise de Templates ")]),_c('v-card',{attrs:{"flat":""}},[_c('v-card-text',{staticClass:"pa-3"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.users,"label":"Usuário","outlined":"","hide-details":"","dense":"","item-text":"value","item-value":"id"},on:{"change":_vm.fechSmsTemplates},model:{value:(_vm.filterUserId),callback:function ($$v) {_vm.filterUserId=$$v},expression:"filterUserId"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.smsTemplateStatus,"label":"Status","outlined":"","hide-details":"","dense":"","item-text":"text","item-value":"value"},on:{"change":_vm.fechSmsTemplates},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-3"},[_c('v-btn',{staticClass:"pl-4",attrs:{"color":"primary"},on:{"click":_vm.fechSmsTemplates}},[_vm._v(" Pesquisar "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiMagnify)+" ")])],1)],1)],1),_c('v-card',{staticClass:"mt-5",attrs:{"flat":""}},[_c('v-data-table',{staticClass:"table-rounded",attrs:{"headers":_vm.headers,"items":_vm.rows,"item-key":"id","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"info","dark":"","text":"","title":"Visualizar","x-small":""},on:{"click":function($event){return _vm.openDialog(item)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiEye))])],1)]}},{key:"item.smsTemplate.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"my-2",attrs:{"color":_vm.resolveStatus(item.smsTemplate.status).color,"small":"","label":""}},[_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.resolveStatus(item.smsTemplate.status).value)+" ")])])]}},{key:"item.smsTemplate.observation",fn:function(ref){
var item = ref.item;
return [(item.smsTemplate.status === _vm.smsTemplate.Refused)?_c('span',[_vm._v(" "+_vm._s(_vm.resolveObservation(item.smsTemplate.observation))+" ")]):_vm._e()]}},{key:"item.smsTemplate.createdAt",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$day(item.smsTemplate.createdAt).format('DD/MM/YYYY HH:mm:ss')))])]}},{key:"item.smsTemplate.serviceIds",fn:function(ref){
var item = ref.item;
return _vm._l((item.smsTemplate.serviceIds),function(service,index){return _c('v-chip',{key:index,staticClass:"ma-1",attrs:{"small":""}},[_c('span',{staticClass:"white--text font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.resolveServiceName(service))+" ")])])})}}],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"width":800,"persistent":""},model:{value:(_vm.dialogTemplate),callback:function ($$v) {_vm.dialogTemplate=$$v},expression:"dialogTemplate"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" "+_vm._s(("SMS Template (#" + (_vm.form.id) + ")"))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","x-small":"","fab":""},on:{"click":function($event){_vm.dialogTemplate = false}}},[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"","dense":"","label":"Nome","readonly":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.smsTypes,"readonly":_vm.form.status !== _vm.smsTemplate.Pending,"hide-details":"","chips":"","label":"Serviços Associados","item-text":"text","item-value":"serviceId","multiple":"","outlined":"","dense":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info","label":"","small":""}},[_vm._v(" "+_vm._s(item.text)+" ")])]}}]),model:{value:(_vm.form.serviceIds),callback:function ($$v) {_vm.$set(_vm.form, "serviceIds", $$v)},expression:"form.serviceIds"}})],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-textarea',{attrs:{"counter":"","outlined":"","dense":"","hide-details":"","label":"Mensagem","readonly":"","rows":"8"},model:{value:(_vm.form.message),callback:function ($$v) {_vm.$set(_vm.form, "message", $$v)},expression:"form.message"}})],1)],1)],1),(_vm.smsTemplate.Pending === _vm.form.status)?_c('v-divider'):_vm._e(),(_vm.smsTemplate.Pending === _vm.form.status)?_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialogTemplateRefuse = true}}},[_vm._v(" Recusar ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.approveTemplate(_vm.form.id, _vm.form.serviceIds)}}},[_vm._v(" Aprovar ")])],1):_vm._e()],1)],1),_c('validation-observer',{ref:"formRefuseTemplate",attrs:{"autocomplete":"off","tag":"form"}},[_c('v-dialog',{attrs:{"width":400,"persistent":""},model:{value:(_vm.dialogTemplateRefuse),callback:function ($$v) {_vm.dialogTemplateRefuse=$$v},expression:"dialogTemplateRefuse"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Recusar Template ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('validation-provider',{attrs:{"name":"Motivo","rules":"required|max:300","vid":"formRefuse.observation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"hide-details":errors.length === 0,"counter":"","outlined":"","dense":"","label":"Motivo"},model:{value:(_vm.formRefuse.observation),callback:function ($$v) {_vm.$set(_vm.formRefuse, "observation", $$v)},expression:"formRefuse.observation"}})]}}])})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":""},on:{"click":function($event){_vm.dialogTemplateRefuse = false}}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){return _vm.refuseTemplate()}}},[_vm._v(" Recusar ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }